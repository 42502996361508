function Timer(props) {
  const { timer, animationState, width, action } = props;
  const style = { width: `calc(${width} - var(--margin))` };

  return (
    <button className="sw-button" onClick={action}>
      <div className={`sw-time ${animationState.timer}`} style={style}>
        <div className={`column center sw-face ${animationState.face}`}>
          <div className={`sw-timer ${animationState.numbers}`}>{timer}</div>
        </div>
        <div className={`sw-flash ${animationState.flash}`}></div>
      </div>
    </button>
  );
}

export default Timer;
