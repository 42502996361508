import { Link, useNavigate, Outlet } from "react-router-dom";
import { UserContext } from "../../context/User/UserProvider";
import { useContext } from "react";
import axios from "../../utils/axios";

function Header() {
  const { auth, setAuth } = useContext(UserContext);
  const navigate = useNavigate();

  const logOutUser = async () => {
    try {
      const today = new Date();
      const date = today.toISOString();
      const data = await axios.post("employee/logout", {
        data: {
          date: date,
          empId: auth.employee._id.toString(),
          type: "logout",
        },
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <header>
        <div className="nav">
          <div className="card">
            <div
              className="logo"
              onClick={() => {
                if (window.location.pathname === "/") {
                  navigate(0);
                } else {
                  navigate("/");
                }
              }}
            >
              Home
            </div>
          </div>
          {auth.accessToken !== "" ? (
            <>
              <div
                className="list-item"
                onClick={() => {
                  navigate("/timesheets");
                }}
              >
                {`${auth?.employee?.firstName} ${auth?.employee?.lastName}`}{" "}
              </div>
              <button
                onClick={async (e) => {
                  setAuth({
                    accessToken: "",
                    employee: {
                      _id: "",
                      firstName: "",
                      lastName: "",
                      jobs: [],
                      role: "",
                    },
                  });
                  await logOutUser();
                  navigate("/login");
                }}
              >
                <p className="btn-loginout">Logout</p>
              </button>
            </>
          ) : (
            <button>
              <Link to="/login">Login</Link>
            </button>
          )}
        </div>
      </header>
      <Outlet />
    </>
  );
}

export default Header;
