import usePage from "@almost_educated/usepage";
import { useEffect } from "react";
import useServer from "../../hooks/useServer";
import useAuth from "../../context/User/useAuth";
function useHome() {
  const employee = useAuth().auth.employee;

  const initialState = {
    body: {
      show: 2,
      welcome: {
        show: true,
      },
      jobs: {
        show: true,
      },
      customers: {
        show: true,
      },
      products: {
        show: false,
      },
      steps: {
        show: false,
      },
      log: {
        show: false,
        logged: "",
        scan: "",
        current: {},
        pause: false,
        clockState: { login: false, tock: false },
        seconds: 0,
        sessionCount: 0,
        clock: "00:00:00",
      },
      processLog: {
        show: false,
        start: "",
        logged: "",
        current: {},
        clockState: { login: false, tock: false },
        seconds: 0,
        sessionCount: 0,
        clock: "00:00:00",
      },
    },

    stores: {
      jobs: [],
      customers: [],
      products: [],
      process: {},
      subProcess: [],
      processSteps: [],
    },
  };

  const signalState = {};

  const page = usePage(initialState, signalState);
  const { load, init } = page;
  const { get, post } = useServer();

  //Customer
  useEffect(() => {
    async function effect() {
      load("stores.customers", {
        reload: () => {
          return get("/customers");
        },
        callback: (data) => {
          if (data) {
            init("stores.customers", data);
          }
        },
      });
    }
    effect();
  }, [get, load, init]);

  //Jobs
  useEffect(() => {
    async function effect() {
      load("stores.jobs", {
        reload: () => {
          return post("/jobs", employee._id);
        },
        callback: (data) => {
          if (data) {
            init("stores.jobs", data);
          }
        },
      });
    }
    effect();
  }, [post, load, employee, init]);

  return page;
}
export default useHome;
