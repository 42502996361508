import { Routes, Route } from "react-router-dom";
import Header from "./components/headers/Header";
import Home from "./pages/Home";
import Login from "./pages/Login";
import "./ink.css";
import "./calendar.css";
import "./ellipse.css";
import Layout from "./pages/Layout";

import UserRefresh from "./context/User/UserRefresh";
import TimeSheets from "./pages/TimeSheets";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/unauthorized" />
        <Route path="*" element={<h1 className="column soft">404</h1>} />
        <Route element={<UserRefresh />}>
          <Route element={<Header />}>
            <Route path="/" element={<Home />} />
            <Route path="timesheets" element={<TimeSheets />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
