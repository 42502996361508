import { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useAuth from "../context/User/useAuth";
import ModalAlert from "../components/ModalAlert";
import axios from "../utils/axios";

const Login = () => {
  const { setAuth } = useAuth();
  const [alerts, setAlert] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    persist: false,
  });
  const axiosLogin = axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 400) {
        return error.response;
      }
      return Promise.reject(error);
    }
  );

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from.pathname || "/";

  const { email, password, persist } = formData;

  const postEmployeeLogin = async (credentials) => {
    const data = await axios.post("/employee/login", {
      data: credentials,
    });
    return data.data;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    loginFormValidate();
  };

  const loginFormValidate = async () => {
    const employee = await postEmployeeLogin(formData);
    if (employee.err === true && employee.field === "email") {
      window.scroll(0, 0);
      setAlert([
        ...alerts,
        {
          id: (alerts.length + 1).toString(),
          type: "error",
          msg: "Invalid Email",
        },
      ]);
    } else if (employee.err === true && employee.field === "password") {
      window.scroll(0, 0);
      setAlert([
        ...alerts,
        {
          id: (alerts.length + 1).toString(),
          type: "error",
          msg: "Invalid Password",
        },
      ]);
    } else {
      if (Object.keys(employee).some((key) => key === "accessToken")) {
        const today = new Date();
        const date = today.toISOString();
        const checkin = await axios.post("/employee/timesheet", {
          data: {
            date: date,
            empId: employee.employee._id.toString(),
            type: "login",
          },
        });
        if (checkin) {
          axios.interceptors.response.eject(axiosLogin);
          setAuth({
            accessToken: employee.accessToken,
            employee: employee.employee,
          });
          navigate(from, { replace: true });
        }
      }
    }
  };

  const handleOnChange = (e) => {
    let updatedState = e.target.value;
    if (e.target.name === "persist") {
      persist === false ? (updatedState = true) : (updatedState = false);
    }
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: updatedState,
    }));
  };
  return (
    <>
      <header>
        <div className="nav">
          <div className="card">
            <div className="logo">
              <Link to="/">Home</Link>
            </div>
            <ul></ul>
          </div>

          <button>
            <Link to="/login">Login</Link>
          </button>
        </div>
      </header>
      <section className="column">
        <ModalAlert alerts={alerts} setAlert={setAlert} />
        <h1>Login</h1>

        <form
          className="form-control onethird list column shadow"
          onSubmit={onSubmit}
        >
          <input
            className="form-input"
            type="text"
            placeholder="email"
            name="email"
            value={email}
            onChange={(e) => {
              handleOnChange(e);
            }}
          />
          <input
            className="form-input"
            type="password"
            placeholder="password"
            name="password"
            value={password}
            onChange={(e) => {
              handleOnChange(e);
            }}
          />
          {/* <div className="pair">
            <label>Stay Logged In</label>
            <input
              className="square"
              type="checkbox"
              name="persist"
              value={persist}
              onChange={(e) => {
                handleOnChange(e);
              }}
            />
          </div> */}
          <button className="btn-default" type="submit">
            Login
          </button>
        </form>
      </section>
    </>
  );
};
export default Login;
