import { createContext, useState } from "react";

export const UserContext = createContext({
  accessToken: "",
  employee: {},
  signal: false, // used to trigger a refresh
});

export const UserProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    accessToken: "",
    employee: {},
    signal: false,
  });

  return (
    <UserContext.Provider value={{ auth, setAuth }}>
      {children}
    </UserContext.Provider>
  );
};
