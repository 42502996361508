import styled from "styled-components";
import React, { forwardRef } from "react";

const validateRegions = (regions) => {
  if (typeof regions !== "string") {
    console.error("Error: grid-template-areas must be a string");
    return false;
  }

  // Remove newlines and extra spaces, then split into rows
  const rows = regions
    .replace(/\s+/g, " ")
    .trim()
    .split("'")
    .filter((row) => row.trim().length > 0);

  // Check if each row is properly formatted
  const properFormat = rows.every((row) =>
    /^[a-zA-Z0-9\s.]+$/.test(row.trim())
  );

  if (!properFormat) {
    console.error(
      "Error: grid-template-areas is not properly formatted. It should be a string of space-separated areas, with each row wrapped in single quotes."
    );
    return false;
  }

  return true;
};

const calculateGridTemplate = (areas) => {
  if (!validateRegions(areas)) {
    return { columns: "1fr", rows: "auto" };
  }

  // Remove newlines and extra spaces, then split into rows
  const rows = areas
    .replace(/\s+/g, " ")
    .trim()
    .split("'")
    .filter((row) => row.trim().length > 0);

  const columnCounts = rows.map((row) => row.trim().split(/\s+/).length);
  const maxColumns = Math.max(...columnCounts);

  return {
    columns: `repeat(${maxColumns}, 1fr)`,
    rows: `repeat(${rows.length}, auto)`,
  };
};

const Style = styled.div`
  display: grid;
  grid-area: ${(props) => props.$region};
  ${(props) =>
    props.$regions
      ? `grid-template-areas: ${props.$regions};
  grid-template-columns: ${calculateGridTemplate(props.$regions).columns};
  grid-auto-rows: min-content;`
      : ""}
  grid-column-gap: var(--gap);
  border-radius: 5px;
  padding: var(--pad);
  margin: var(--mar);
  ${(props) => props.$more}
`;

const Card = forwardRef((props, ref) => {
  const { children, regions, region, style } = props;
  return (
    <Style ref={ref} $more={style} $regions={regions} $region={region}>
      {children}
    </Style>
  );
});

const SubCardStyle = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;
  ${(props) => props.$more}
`;

export const SubCard = ({ children, style, onClick }) => {
  return (
    <SubCardStyle $more={style} onClick={(e) => onClick(e)}>
      {children}
    </SubCardStyle>
  );
};

Card.SubCard = SubCard;
export default Card;
