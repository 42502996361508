import { useEffect, useRef } from "react";

// Parent should have state for what is scanner
function HiddenBarcodeReader(props) {
  const { onSubmit, onScan, scan } = props;
  const ref = useRef(null);

  const autoFocus = () => {
    ref.current.focus();
  };

  useEffect(() => {
    ref.current.focus();
  }, []);

  return (
    <div>
      <form
        onSubmit={(e) => {
          onSubmit(e);
        }}
      >
        <input
          id="hidden-barcode-reader"
          type="text"
          className="sw-barcode"
          value={scan}
          ref={ref}
          onBlur={() => {
            autoFocus();
          }}
          onChange={(e) => {
            onScan(e);
          }}
        />
      </form>
    </div>
  );
}
export default HiddenBarcodeReader;
