import useServer from "../../hooks/useServer";

function Customers(props) {
  const { page } = props;
  const { post } = useServer();

  const handleOnSetCustomer = async (customer) => {
    const response = await post("/customers/find/products", {
      customerId: customer._id,
    });
    const response2 = await post("/customers/find/subprocess", {
      customerId: customer._id,
    });
    console.log(response2);
    if (response && response2) {
      page.init("stores.products", response);
      page.init("stores.subProcess", response2.subProcess);
      page.set("body.products.show", true);
      page.set("body.welcome.show", false);
      page.set("body.jobs.show", false);
      page.set("body.customers.show", false);
    }
  };

  return (
    <div className="column">
      <h3>Customers</h3>
      <div>
        {page.state.stores.customers.map((customer, index) => {
          return (
            <button
              key={customer._id}
              className="btn-default btn-factory item shadow"
              onClick={() => {
                handleOnSetCustomer(customer);
              }}
            >
              {customer.profileName}
            </button>
          );
        })}
      </div>
    </div>
  );
}
export default Customers;
