import styled from "styled-components";
import Card from "../components/Card";
import Text from "../components/Text";
import Ellipse from "../icons/Ellipse";
import { useEffect, useState } from "react";
import useAuth from "../context/User/useAuth";
import { Calendar } from "react-calendar";
import useServer from "../hooks/useServer";

const Tile = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function EmployeeCalendar(props) {
  const { post } = useServer();
  const employee = useAuth().auth.employee;
  const [loading, setLoading] = useState(true);
  const { page } = props;
  const { set } = page;
  const { timeSheets } = page.state;

  useEffect(() => {
    if (timeSheets[0].days) {
      setLoading(false);
    }
  }, [timeSheets]);

  //Format Login/Logout
  const format = (data) => {
    const date = new Date(data);
    const local = date.toLocaleDateString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });
    let string = local.split(",")[1].trim();
    if (string[0] === "0") {
      string = string.slice(1);
    }
    return string;
  };

  const tileContent = ({ date }) => {
    if (loading) return <Ellipse />;
    const day = new Date(date);
    day.setHours(0, 0, 0, 0);

    //Find Total Hours
    let clockin = "";
    let clockout = "";
    let display = 0;

    Object.entries(timeSheets[0].days).forEach(([key, value]) => {
      const { earliestLogin, latestLogout, hours } = value;
      const login = new Date(earliestLogin.date);
      login.setHours(0, 0, 0, 0);
      if (day.getTime() === login.getTime()) {
        clockin = format(earliestLogin.date);
        clockout = format(latestLogout.date);
        display = hours;
      }
    });

    return display === 0 ? null : (
      <Tile>
        <Text title={`${clockin} - ${clockout}`} style={`font-size: 6pt;`} />
        <Text title={`${display?.toFixed(2) ?? "N/A"} Hours`} />
      </Tile>
    );
  };

  return (
    <Card>
      <Calendar
        tileContent={tileContent}
        onActiveStartDateChange={async (action) => {
          set("timeSheets", [{ days: {} }]);
          setLoading(true);
          const data = await post("/employees/timesheets", {
            empId: employee._id,
            date: action.activeStartDate,
          });
          if (data) {
            set("timeSheets", data);
          }
        }}
      />
    </Card>
  );
}
