function CheckCircle() {
  return (
    <svg
      version="1.1"
      id="Layer_2_00000086651400401157159540000003381460901535495102_"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16.3"
      height="16.3"
      viewBox="0 0 16.3 16.3"
      style={{ enableBackground: "new 0 0 16.3 16.3" }}
      //   xml:space="preserve"
    >
      <path
        d="M8.2,16.3C3.7,16.3,0,12.7,0,8.2S3.7,0,8.2,0c4.5,0,8.2,3.7,8.2,8.2C16.3,12.7,12.7,16.3,8.2,16.3z M8.2,1
      C4.2,1,1,4.2,1,8.2c0,4,3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2c0,0,0,0,0,0C15.3,4.2,12.1,1,8.2,1z"
      />
      <path
        d="M11.7,5.8c-0.2-0.2-0.5-0.2-0.7,0L7.3,9.4L5.6,7.7c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7l2.1,2.1
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4-0.1l4-4C11.9,6.3,11.9,6,11.7,5.8z"
      />
    </svg>
  );
}

export default CheckCircle;
