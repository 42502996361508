import useServer from "../../hooks/useServer";
function Products(props) {
  const { page } = props;
  const { post } = useServer();

  const processSelect = async (product) => {
    const response = await post("/customers/find/process", {
      processId: product.processId,
    });
    if (response) {
      page.init("stores.process", response);
      page.init("stores.processSteps", response.steps);
      page.set("body.log.current", {
        ...page.state.body.log.current,
        productName: product.name,
        productId: product._id,
        processId: product.processId,
        customerId: product.customerId,
      });
      page.set("body.products.show", false);
      page.set("body.steps.show", true);
    }
  };

  const subProcessSelect = async (sub) => {
    page.set("body.processLog.current", sub);
    page.set("body.products.show", false);
    page.set("body.processLog.show", true);
  };

  //COMPONENT
  return (
    <div className="column">
      <h3>Products</h3>
      <div>
        <button
          className="btn-default btn-factory item shadow"
          onClick={() => {
            page.set("body.products.show", false);
            page.set("body.jobs.show", true);
            page.set("body.customers.show", true);
          }}
        >
          🞀🞀 Customers
        </button>

        {page.state.stores.products.map((product) => {
          return (
            <button
              key={product._id}
              className="btn-default btn-factory item shadow"
              onClick={() => {
                processSelect(product);
              }}
              style={
                product.archived === true ? { backgroundColor: "orange" } : {}
              }
            >
              {product.name}
            </button>
          );
        })}
      </div>
      {/* <h3>Processes</h3>
      <div>
        {page.state.stores.subProcess.map((sub) => {
          return (
            <button
              key={sub._id}
              className="btn-default btn-factory item shadow"
              onClick={() => {
                subProcessSelect(sub);
              }}
            >
              {sub.name}
            </button>
          );
        })}
      </div> */}
    </div>
  );
}
export default Products;
