import usePage from "@almost_educated/usepage";
import { useEffect } from "react";
import useServer from "../hooks/useServer";
import useAuth from "../context/User/useAuth";

function useCalendar() {
  const employee = useAuth().auth.employee;

  const initialState = {
    selectedMonth: new Date(),
    timeSheets: [{ days: {} }],
  };

  const page = usePage(initialState);
  const { load, init } = page;
  const { post } = useServer();

  //LOAD TIMESHEETS
  useEffect(() => {
    async function effect() {
      if (!employee || !page?.state.selectedMonth) return;
      load("timeSheets", {
        reload: () => {
          return post("/employees/timesheets", {
            empId: employee._id,
            date: page.state.selectedMonth,
          });
        },
        callback: (data) => {
          if (data) {
            init("timeSheets", data);
          }
        },
      });
    }
    effect();
  }, [load, init, page.state.selectedMonth, post, employee._id]);

  return page;
}
export default useCalendar;
