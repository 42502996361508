function Alert(props) {
  const { msg, type } = props;
  return (
    <div className="card center sw-msg">
      <div className="column center">
        <h1 className={type}>{msg}</h1>
      </div>
    </div>
  );
}

export default Alert;
