function Pause() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="150px"
      height="150px"
      viewBox="0 0 150 150"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M87,52.1L87,52.1c-3.1,0-5.6,2.5-5.6,5.6V88c0,3.1,2.5,5.6,5.6,5.6h0c3.1,0,5.6-2.5,5.6-5.6V57.7
            C92.6,54.6,90.1,52.1,87,52.1z"
        />
        <path
          d="M64,52.1L64,52.1c-3.1,0-5.6,2.5-5.6,5.6V88c0,3.1,2.5,5.6,5.6,5.6h0c3.1,0,5.6-2.5,5.6-5.6V57.7
            C69.6,54.6,67.1,52.1,64,52.1z"
        />
      </g>
    </svg>
  );
}
export default Pause;
