import { useState, useEffect, useCallback } from "react";
import useServer from "../../hooks/useServer";
import Alert from "./Alert";
import End from "../../icons/End";
import Timer from "./Timer";
import useAnimations from "../../hooks/useAnimations";

function ProcessLog(props) {
  const { page, employee } = props;
  const [alert, setAlert] = useState({ msg: "", type: "" });
  const { post } = useServer();
  const current = page.state.body.processLog.current;
  const log = page.state.body.processLog;

  const { set } = page;
  const {
    animationState,
    animateStopWatch,
    animateScan,
    animateSuccess,
    animateEmp,
    animateError,
  } = useAnimations();

  //EFFECTS
  const callbackTick = useCallback(() => {
    const tick = (now) => {
      let remain = now;
      let hours = Math.floor(remain / 3600);
      remain -= hours * 3600;
      let mins = Math.floor(remain / 60);
      remain -= mins * 60;
      let secs = remain;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (mins < 10) {
        mins = "0" + mins;
      }
      if (secs < 10) {
        secs = "0" + secs;
      }
      set("body.processLog.clock", `${hours}:${mins}:${secs}`);
    };
    tick(log.seconds);
  }, [set, log.seconds]);

  // Start Clock
  useEffect(() => {
    let interval = null;
    if (log.clockState.tock) {
      interval = setInterval(() => {
        let next = log.seconds + 1;
        set("body.processLog.seconds", next);
        callbackTick();
      }, 1000);
    } else if (!log.clockState.tock && log.seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [log, callbackTick]);

  //FUNCTIONS

  const goBack = () => {
    if (log.seconds > 0) {
      logProcess(log.sessionCount);
    }
    page.set("body.processLog.clockState", {
      ...log.clockState,
      tock: false,
    });
    page.set("body.processLog.seconds", 0);
    page.set("body.processLog.clock", "00:00:00");
    page.set("body.processLog.sessionCount", 0);
    page.set("body.processLog.show", false);
    page.set("body.steps.show", true);
  };

  const generateBodyData = (when) => {
    const milli = Date.now();
    const date = new Date(milli);
    const now = date.toISOString();
    const bodyData = {
      processId: current._id,
      time: 0,
      empId: employee._id,
      empFirstName: employee.firstName,
      empLastName: employee.lastName,
    };

    if (when === "start") {
      bodyData.start = now;
    }
    if (when === "stop") {
      bodyData._id = log.logged;
      bodyData.stop = now;
    }
    return bodyData;
  };

  const logProcess = async (sequence) => {
    setAlert({ msg: "", type: "" });

    if (sequence === "first" && page.state.body.processLog.sessionCount === 0) {
      animateScan();
      animateSuccess();
      animateStopWatch();
      page.set("body.processLog.clockState", { ...log.clockState, tock: true });

      const bodyData = generateBodyData("start");
      page.set("body.processLog.start", bodyData.start);
      const data = await post("log/process/start", bodyData);
      if (data) {
        console.log(data);
        page.set("body.processLog.logged", data.data._id);
      }
      page.set("body.processLog.sessionCount", 1);

      return;
    }

    if (sequence === "last") {
      animateEmp();
      setAlert({ msg: "Finished", type: "alert-success" });
      page.set("body.processLog.sessionCount", 0);
      page.set("body.processLog.clockState", {
        ...log.clockState,
        tock: false,
      });
      const bodyData = generateBodyData("stop");
      bodyData.start = log.start;
      const data = await post("log/process/stop", bodyData);

      page.set("body.processLog.scan", "");
      page.set("body.processLog.logged", "");
      page.set("body.processLog.seconds", 0);
      page.set("body.processLog.clock", "00:00:00");
    }
  };

  //COMPONENT
  return (
    <div className="column">
      <h1>Process Logging</h1>
      <div className="column almostfull list shadow soft">
        <div className="card soft item">
          <button
            className="btn-default item"
            onClick={() => {
              goBack();
            }}
          >
            🞀🞀 Steps
          </button>
          <h1 className="item">{current?.name}</h1>
        </div>
        <Alert msg={alert.msg} type={alert.type} />
        <div className="card center">
          <div className="bar">
            <Timer
              timer={log.clock}
              animationState={animationState}
              width={"100%"}
              action={(e) => {
                console.log("action");
                // if (page.state.body.processLog.pause) {
                //   unpause(e);
                //   return;
                // }
                logProcess("first");
              }}
            />
            <button
              className="btn-default btn-end"
              onClick={(e) => {
                logProcess("last");
              }}
            >
              <End />
            </button>
          </div>
        </div>

        <section className="bar list split">
          <div className="column c-left">
            <h2 className="item">Process Info</h2>
            <div className={"item"}> {current.steps[0].desc}</div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default ProcessLog;
