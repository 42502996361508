import axiosProtected from "../../utils/axios";
import { useEffect } from "react";
import useAuth from "./useAuth";
import useRefresh from "./useRefresh";

function useIntercept() {
  const { auth } = useAuth();
  const refresh = useRefresh();

  useEffect(() => {
    const request = axiosProtected.interceptors.request.use(
      (config) => {
        if (!config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const reponse = axiosProtected.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const accessToken = await refresh();
          //below may need to refrence the original request var not the axiosProtected
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return axiosProtected(originalRequest);
        }
        if (error?.response?.status === 400) {
          return error.response;
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axiosProtected.interceptors.request.eject(request);
      axiosProtected.interceptors.response.eject(reponse);
    };
  }, [auth, refresh]);

  return axiosProtected;
}

export default useIntercept;
