function End() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      widht="150px"
      height="150px"
      viewBox="0 0 150 150"
      xmlSpace="preserve"
    >
      <path
        d="M96,44.1c-1.9,0-3.4,1.5-3.4,3.4v17.6L58.7,45.5c-2.7-1.6-6.2,0.4-6.2,3.6v42.9c0,3.2,3.4,5.2,6.2,3.6l33.9-19.6v16.7
	c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4V47.5C99.4,45.6,97.9,44.1,96,44.1z M86.8,71.8L61.7,86c-1,0.6-2.2-0.1-2.2-1.3V56.3
	c0-1.1,1.2-1.8,2.2-1.3l25.1,14.2C87.8,69.8,87.8,71.2,86.8,71.8z"
      />
    </svg>
  );
}

export default End;
