import { useReducer } from "react";
function useAnimations() {
  const animationsInitialState = {
    type: "",
    timer: "",
    flash: "",
    numbers: "",
    face: "",
  };
  const init = (animationsInitialState) => {
    return animationsInitialState;
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "reset":
        return init(action.payload);
      case "timer":
        return {
          ...state,
          timer: action.payload,
        };
      case "flash":
        return {
          ...state,
          flash: action.payload,
        };
      case "numbers":
        return {
          ...state,
          numbers: action.payload,
        };
      case "face":
        return { ...state, face: action.payload };

      default:
        return state;
    }
  };
  const [animationState, animateDispatch] = useReducer(
    reducer,
    animationsInitialState,
    init
  );

  const animateError = () => {
    animateDispatch({ type: "reset", payload: animationsInitialState });
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        animateDispatch({ type: "timer", payload: "sw-animate-error" });
        animateDispatch({ type: "flash", payload: "sw-error" });
      });
    });
  };

  const animateSuccess = () => {
    animateDispatch({ type: "reset", payload: animationsInitialState });
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        animateDispatch({ type: "timer", payload: "sw-animate-success" });
      });
    });
  };

  const animateStopWatch = () => {
    animateDispatch({ type: "reset", payload: animationsInitialState });
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        animateDispatch({ type: "face", payload: "sw-animate" });
        animateDispatch({ type: "numbers", payload: "sw-numbers-green" });
      });
    });
  };

  const animateScan = () => {
    animateDispatch({ type: "reset", payload: animationsInitialState });
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        animateDispatch({ type: "flash", payload: "sw-scan" });
      });
    });
  };

  const animateEmp = () => {
    animateDispatch({ type: "reset", payload: animationsInitialState });
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        animateDispatch({ type: "flash", payload: "emp-scan" });
      });
    });
  };

  return {
    animateError,
    animateSuccess,
    animateStopWatch,
    animateScan,
    animateEmp,
    animationState,
  };
}

export default useAnimations;
